<template>
  <div class="container">
    <div class="header-containter">
      <div class="header-bg" />
      <div ref="navbar" class="navbar" :class="navClass">
        <div class="nav-title">
          <i class="go-back-btn el-icon-arrow-left" @click="goBack" />
        </div>
        <div class="nav-acname">
          奖品详情
        </div>
        <div class="nav-btn-containter">
          <!-- 中奖查询 -->
        </div>
      </div>
      <header class="header">
        <span class="header-desc">{{ acName }}</span>
      </header>
    </div>
    <div class="list-containter">
      <div class="list-content">
        <div
          ref="prizeItem"
          class="item"
          :class="prizeFixed ? 'is-fixed' : ''"
          :style="styleStr"
        >
          <div class="item-img">
            <img :src="prizeObj.picUrl" alt="img" />
          </div>
          <div class="item-content">
            <div class="item-title">
              <div class="summary">
                <div class="prize-name">
                  <div>
                    <span class="name">奖品：{{ prizeObj.prizeName }}</span>
                    x<span class="prize-balance">{{ prizeObj.balance }}</span>
                    <!-- 这里的 x 是不要删，用来展示奖品数量的，例如 iphone12 x 10-->
                  </div>
                </div>
              </div>
              <div class="timer">
                <div
                  v-if="!prizeObj.before24H && !prizeObj.openStatus"
                  class="countdown-time"
                >
                  <div class="time-item time-h">
                    <span>{{ prizeObj.h }}</span>
                  </div>
                  <!-- <div class="time-split">&#58;</div> -->
                  <div class="time-split">时</div>
                  <div class="time-item time-m">
                    <span>{{ prizeObj.m }}</span>
                  </div>
                  <div class="time-split">分</div>
                  <div class="time-item time-s">
                    <span>{{ prizeObj.s }}</span>
                  </div>
                  <div class="time-split">秒</div>
                  <div class="countdown-title">后开奖</div>
                </div>
                <div
                  v-if="!prizeObj.before24H && prizeObj.openStatus"
                  class="countdown-time"
                >
                  <div
                    v-if="new Date().getFullYear() + '' !== prizeObj.year"
                    class="time-item"
                  >
                    <span>{{ prizeObj.year }}</span>
                  </div>
                  <div
                    v-if="new Date().getFullYear() + '' !== prizeObj.year"
                    class="time-split"
                  >
                    年
                  </div>
                  <div class="time-item">
                    <span>{{ prizeObj.month }}</span>
                  </div>
                  <div class="time-split">月</div>
                  <div class="time-item">
                    <span>{{ prizeObj.day }}</span>
                  </div>
                  <div class="time-split">日</div>
                  <div class="time-split">
                    {{ prizeObj.hour }}:{{ prizeObj.min }}:{{ prizeObj.sec }}
                  </div>
                  <div class="prize-timerover">已开奖</div>
                </div>
                <div v-if="prizeObj.before24H" class="date-time">
                  <div
                    v-if="new Date().getFullYear() + '' !== prizeObj.year"
                    class="time-item"
                  >
                    <span>{{ prizeObj.year }}</span>
                  </div>
                  <div
                    v-if="new Date().getFullYear() + '' !== prizeObj.year"
                    class="time-split"
                  >
                    年
                  </div>
                  <div class="time-item">
                    <span>{{ prizeObj.month }}</span>
                  </div>
                  <div class="time-split">月</div>
                  <div class="time-item">
                    <span>{{ prizeObj.day }}</span>
                  </div>
                  <div class="time-split">日</div>
                  <div class="time-split">
                    {{ prizeObj.hour }}:{{ prizeObj.min }}:{{ prizeObj.sec }}
                  </div>
                  <!--<div v-if="prizeObj.openStatus" class="prize-timerover">已开奖</div>-->
                  <div class="countdown-title">自动开奖</div>
                </div>
              </div>
              <div class="grade">
                <div class="grade-content">
                  <div class="icon">
                    <img src="../../assets/grade.png" alt="等级" />
                  </div>
                  <div class="text">
                    <span>{{ prizeObj.grade }}</span>
                  </div>
                </div>
              </div>
              <div class="provider">
                <span class="sponsor">{{ prizeObj.sponsor }}</span
                >赞助
              </div>
            </div>
          </div>
        </div>
        <div v-if="prizeFixed" class="item empty" />
        <div ref="table" class="table">
          <el-tabs v-model="activeTabName">
            <el-tab-pane label="参与名单" name="alluser">
              <usertable
                :table-data="alluser"
                :type="0"
                :total="allTotal"
                @submit="getAllUser"
              />
            </el-tab-pane>
            <el-tab-pane label="中奖名单" name="luckyuser">
              <usertable
                :table-data="luckyuser"
                :type="1"
                :total="luckTotal"
                @submit="getLuckyuser"
              />
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
$headerHeight: 200px;
$navHeight: 40px;
$navFontSize: 12px;
$headerDescFontSize: 22px;

.container {
  margin: 0 auto;
  width: 100%;
  height: 100%;
  // overflow: hidden;
  overflow-y: scroll;
  // background-color: #f4f4f4;
  background: #07090d;

  // display: flex;
  // flex-direction: column;
  .header-containter {
    background: url('../../assets/prizelist/header-bg.png');
    background-size: auto 100%;
    background-position: center center;
    position: relative;
    height: $headerHeight;
  }
  .list-containter {
    width: 100%;
    // flex-grow: 1;
    position: relative;
    margin: -50px auto auto auto;
    padding: 0 10px 10px;
    box-sizing: border-box;
    background: #07090d;
    // min-height: 90vh;
  }
}
.header-containter {
  .header-bg {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.4);
  }
  .navbar {
    z-index: 9;
    width: 100%;
    box-sizing: border-box;
    padding: 0 20px;
    height: $navHeight;
    position: fixed;
    display: flex;
    color: #fff;
    justify-content: space-between;
  }
  .nav-acname {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: $navFontSize;
  }
  $stepTotal: 30;
  @mixin steploop($i) {
    .navbar.step#{$i} {
      $op: $i / ($stepTotal - 1);
      background-color: rgba(6, 12, 12, round($op * 10000) / 10000);
    }
  }
  @for $i from 0 through $stepTotal {
    @include steploop($i);
  }
  .header {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: $navHeight 8% 10px 8%;
    box-sizing: border-box;
    position: relative;
    .header-desc {
      margin-top: -10%;
      text-align: center;
      font-family: PingFangSC-Regular;
      font-size: $headerDescFontSize;
      color: #fff;
      font-weight: bold;
      font-family: serif;
    }
  }
}
.navbar {
  .nav-name {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-weight: bold;
    font-size: $navFontSize;
    width: 100%;
  }
  .nav-title,
  .nav-btn-containter {
    width: 120px;
    height: 100%;
    display: inline-block;
  }
  .nav-title {
    display: flex;
    align-items: center;
    color: #fff;
    font-weight: bold;
    font-size: 20px;
  }
  .go-back-btn {
    font-size: $navFontSize;
    color: #fff;
    font-weight: bold;
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
    cursor: pointer;
    &:hover {
      color: #1ab4ab;
    }
  }
}
// 奖品元素
.list-containter {
  .list-content {
    // max-width: 840px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }
  .item {
    // margin-bottom: 50px;
    // max-width: 300px;
    position: relative;
    background-color: #fff;
    margin: 0 10px;
    // width: 35%;
    width: 85%;
    padding: 20px;
    // border-radius: 4px;
    box-sizing: border-box;
    // &.is-fixed {
    //   position: fixed;
    //   top: $navHeight;
    //   left: 10px;
    // }
    // &.empty {
    //   background-color: transparent;
    // }
  }
  .table {
    width: 85%;
    margin: 0 10px;
    background-color: #fff;
    padding: 5px 20px;
    box-sizing: border-box;
  }
}
.item {
  .item-img {
    text-align: center;
    margin-bottom: 8px;
    img {
      height: 90px;
    }
  }
  .prize-name {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    font-size: 13px;
    font-weight: bold;
    font-family: PingFangSC-Semibold;
  }
  .prize-balance {
    margin-left: 2px;
    color: #1ab4ab;
  }
  .timer {
    margin-top: 15px;
    .countdown-time,
    .date-time {
      font-family: PingFangSC-Regular;
      display: flex;
      align-items: center;
      font-size: 11px;
      line-height: 11px;
      color: #333;
      .prize-timerover {
        color: #f00;
        margin-left: 2px;
      }
    }
    .time-item {
      font-weight: bold;
      color: #1ab4ab;
    }
    .time-split {
      margin: 0 1px;
      line-height: 10px;
    }
  }
  .grade {
    position: absolute;
    right: 20px;
    bottom: 45px;
    font-family: PingFangSC-Regular;
    display: flex;
    align-items: center;
    .grade-content {
      border: 0.2px solid #1ab4ab;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      position: relative;
      box-sizing: border-box;
    }
    .icon {
      height: 100%;
      box-sizing: border-box;
      padding: 1px 4px;
      background-color: #1ab4ab;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        height: 100%;
        width: 12px;
      }
    }
    .text {
      height: 100%;
      box-sizing: border-box;
      padding: 1px 4px 1px -1px;
      display: flex;
      align-items: center;
      justify-content: center;
      span {
        font-size: 8px;
        margin-left: 4px;
        margin-right: 4px;
      }
    }
  }
  .provider {
    // position: absolute;
    // bottom: 30px;
    margin-top: 10px;
    font-family: PingFangSC-Regular;
    font-size: 11px;
    color: #888;
    .sponsor {
      color: #1ab4ab;
      display: inline-block;
      margin-right: 4px;
    }
  }
}
@media screen and (min-width: 1024px) and (min-device-width: 1024px) {
  .container .list-containter {
    .list-content {
      margin: 0 auto;
      max-width: 840px;
      align-items: flex-start;
      flex-wrap: nowrap;
    }
  }

  .item {
    width: 35%;
    max-width: 300px;
    &.is-fixed {
      position: fixed;
      top: $navHeight;
      left: 10px;
    }
    &.empty {
      background-color: transparent;
    }
  }
  .table {
    width: 70%;
    flex: 1;
  }
}
</style>
<style lang="scss">
.table {
  .el-tabs__item {
    font-size: 12px;
  }
}
.el-tabs__item.is-active,
.el-tabs__item:hover {
  color: #169c94;
}
.el-tabs__active-bar {
  background-color: #169c94;
}
</style>

<script>
import acdetail from './acdetail.js';
export default acdetail;
</script>
