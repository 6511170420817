import { getUrlKey, getGMT8Date } from '@/apis/utils';
import usertable from './components/usertable/index.vue';
import { getActivity, getJoinUser, getLuckUser } from '@/apis/activity';
export default {
  name: 'acdetail',
  components: {
    usertable
  },
  data() {
    return {
      prizeFixed: false,
      prizeitemLeft: 0,
      styleStr: '',
      // 表格数据，参与名单
      alluser: [
        // {
        //   number: '5601',
        //   nickName: 'dsdsg四大奥奥奥奥f',
        //   weighted: '4'
        // },
        // {
        //   number: '56031',
        //   nickName: 'dsdsg四大奥奥奥奥f',
        //   weighted: '4'
        // },
        // {
        //   number: '56021',
        //   nickName: 'dsdsg四大奥奥奥奥f',
        //   weighted: '4'
        // },
        // {
        //   number: '56301',
        //   nickName: 'dsdsg四大奥奥奥奥f',
        //   weighted: '4'
        // },
        // {
        //   number: '56901',
        //   nickName: 'dsdsg四大奥奥奥奥f',
        //   weighted: '4'
        // },
        // {
        //   number: '5601',
        //   nickName: 'dsdsg四大奥奥奥奥f',
        //   weighted: '4'
        // }
      ],
      // 表格数据，中奖名单
      luckyuser: [
        // {
        //   number: '56301',
        //   nickName: 'dsdsg四大奥奥奥奥f',
        //   weighted: '4'
        // },
        // {
        //   number: '56901',
        //   nickName: 'dsdsg四大奥奥奥奥f',
        //   weighted: '4'
        // },
        // {
        //   number: '5601',
        //   nickName: 'dsdsg四大奥奥奥奥f',
        //   weighted: '4'
        // }
      ],
      activeTabName: 'alluser', // alluser参与人员  luckyuser中奖用户
      scrollMax: 300,
      navClass: '',
      prizeObj: {
        // id: 106,
        // grade: '四等奖',
        // prizeName: '帅张专属扑克牌2副',
        // luckCode: '62E2DE7B-C41C-4A94-9B31-AB66AC7A7BF1',
        // balance: 100,
        // picUrl: 'https://twowaterimage.oss-cn-beijing.aliyuncs.com/upload/4_1616684291650.png',
        // openTime: '2021-03-25 23:59:00',
        // sponsor: 'stormzhang'
      },
      acName: '', // 活动名称
      // prizeName: '', // 奖品名称
      // balance: '100', // 奖品数量
      // sponsor: 'stormz', // 赞助商
      // h: '00',
      // m: '00',
      // s: '00',
      activeId: 0, // 活动ID
      prizeId: 0, // 奖品ID
      luckCode: '', // 奖品编码
      // openStatus: true, // 是否开奖
      promiseTimer: '', // 倒计时定时器
      // timeInfo: {}, // 当开奖时间比当前时间晚超 24 小时的时间信息
      allPage: 1, // 参与名单页码
      allSize: 10, // 参与名单每页大小
      allTotal: 0, // 参与名单总数
      luckPage: 1, // 中奖名单页码
      luckSize: 10, // 中奖名单每页大小
      luckTotal: 0 // 中奖名单总数
    };
  },
  created() {
    // 从url中获取活动相关参数
    const activeId = getUrlKey('activeId');
    const prizeId = getUrlKey('prizeId');
    const luckCode = getUrlKey('luckCode');
    // console.log('activeId=' + activeId)
    // console.log('prizeId=' + prizeId)
    // console.log('luckCode=' + luckCode)
    this.activeId = activeId;
    this.prizeId = prizeId;
    this.luckCode = luckCode;
  },
  mounted() {
    this.prizeitemLeft = this.$refs.prizeItem.getBoundingClientRect().left;
    // 设置滚动渐变效果
    const stepTotal = 30;
    const stepHeight = this.scrollMax / stepTotal;
    this.$el.addEventListener('scroll', e => {
      if (this.$el.scrollTop >= this.scrollMax - 2) {
        this.navClass = `step${stepTotal - 1}`;
      } else {
        this.navClass = `step${Math.floor(
          stepTotal - (this.scrollMax - this.$el.scrollTop) / stepHeight
        )}`;
      }
      this.handleScroll();
    });

    this.getActivityInfo(this.activeId, this.prizeId);
    this.getAllUser('', this.pageNo);
  },
  methods: {
    handleScroll() {
      // 滚动事件，处理奖品item固定位置
      const navbarHeight = this.$refs.navbar.getBoundingClientRect().height;
      const offsetTop = this.$refs.prizeItem.getBoundingClientRect().top;
      const tableTop = this.$refs.table.getBoundingClientRect().top;
      if (navbarHeight.toFixed(0) > offsetTop) {
        this.styleStr = `left: ${this.prizeitemLeft - 20}px;`;
        this.prizeFixed = true;
        if (navbarHeight.toFixed(0) < tableTop) {
          this.prizeFixed = false;
          this.styleStr = `left: 0px;`;
        }
      } else {
        this.prizeFixed = false;
        this.styleStr = `left: 0px;`;
      }
    },
    goBack() {
      // 返回上一页
      // this.$router.push({ path: '/', query: { ts: Date.now() }})
      this.$router.go(-1);
    },
    getLuckyuser(key, pageNo) {
      const _this = this;
      // 点击中奖用户的搜索时
      getLuckUser({
        activityId: _this.activeId,
        pageNo: pageNo,
        pageSize: _this.luckSize,
        searchKey: key,
        luckCode: _this.luckCode
      })
        .then(res => {
          let data = this.handleResponse(res);
          if (data.code === 200) {
            const totalCount = data.total;
            _this.luckTotal = totalCount;
            data = data.data;
            _this.luckyuser.length = 0;
            _this.luckyuser = data;
          }
        })
        .catch(err => {
          this.$message({
            message: '查询中奖名单出错！！！',
            customClass: 'ballMsg',
            type: 'error',
            duration: 3000,
            center: true,
            offset: 300
          });
          console.log(err);
        });
    },
    getAllUser(key, pageNo) {
      const _this = this;
      // 点击全部参与用户的搜索时
      getJoinUser({
        activityId: _this.activeId,
        pageSize: _this.allSize,
        searchKey: key,
        pageNo: pageNo
      })
        .then(res => {
          let data = this.handleResponse(res);
          if (data.code === 200) {
            const totalCount = data.total;
            data = data.data;
            _this.alluser.length = 0;
            _this.alluser = data;
            _this.allTotal = totalCount;
          }
        })
        .catch(err => {
          this.$message({
            message: '查询参与名单出错！！！',
            customClass: 'ballMsg',
            type: 'error',
            duration: 3000,
            center: true,
            offset: 300
          });
          console.log(err);
        });
    },
    getActivityInfo(activityId, prizeId) {
      const _this = this;
      const now = getGMT8Date();
      let countDown = false;
      getActivity(activityId)
        .then(res => {
          let data = this.handleResponse(res);
          if (data.code === 200) {
            data = data.data;
            _this.acName = data.activityName;
            data.prizes.forEach(function(e) {
              // e.openTime = '2021-05-21 00:00:00'
              if (e.id !== parseInt(prizeId)) {
                return false;
              }
              // _this.prizeName = e.prizeName
              // _this.balance = e.balance
              // _this.sponsor = e.sponsor
              _this.prizeObj = e;
              // console.log('奖品：', e)
              const flag = now < Date.parse(e.openTime.replace(/-/g, '/'));
              // 未开奖
              // _this.openStatus = false
              const remainTime = parseInt(
                (Date.parse(e.openTime.replace(/-/g, '/')) - getGMT8Date()) /
                  1000
              );
              // _this.h = Math.floor((remainTime / 3600) % 24) + Math.floor(remainTime / 3600 / 24) * 24
              _this.prizeObj.h =
                Math.floor((remainTime / 3600) % 24) +
                Math.floor(remainTime / 3600 / 24) * 24;
              // _this.m = Math.floor((remainTime / 60) % 60)
              _this.prizeObj.m = Math.floor((remainTime / 60) % 60);
              _this.prizeObj.s = Math.floor(remainTime % 60);
              // _this.s = Math.floor(remainTime % 60)
              _this.prizeObj.before24H = false;

              if (parseInt(_this.prizeObj.h) >= 24) {
                _this.prizeObj.before24H = true;
              }
              // 超过 24 小时
              // _this.timeInfo.year = e.openTime.substr(0, 4)
              _this.prizeObj.year = e.openTime.substr(0, 4);
              // console.log(prize.year, '年')
              // _this.timeInfo.month = e.openTime.substr(5, 2)
              _this.prizeObj.month = e.openTime.substr(5, 2);
              // console.log(prize.month, '月')
              // _this.timeInfo.day = e.openTime.substr(8, 2)
              _this.prizeObj.day = e.openTime.substr(8, 2);
              // console.log(prize.day, '日')
              // _this.timeInfo.hour = e.openTime.substr(11, 2)
              _this.prizeObj.hour = e.openTime.substr(11, 2);
              // console.log(prize.hour, '时')
              // _this.timeInfo.min = e.openTime.substr(14, 2)
              _this.prizeObj.min = e.openTime.substr(14, 2);
              // console.log(prize.min, '分')
              // _this.timeInfo.sec = e.openTime.substr(17, 2)
              _this.prizeObj.sec = e.openTime.substr(17, 2);
              // console.log(prize.sec, '秒')
              // }
              _this.prizeObj.openStatus = true;
              if (flag) {
                // 未开奖
                _this.prizeObj.openStatus = false;
                countDown = true;
              }
              return true;
            });
          }
          if (countDown) {
            this.countDown();
          }
        })
        .catch(err => {
          this.$message({
            message: '查询奖品数据出错！！！',
            customClass: 'ballMsg',
            type: 'error',
            duration: 3000,
            center: true,
            offset: 300
          });
          console.log(err);
        });
    },
    countDown() {
      const self = this;
      clearInterval(this.promiseTimer);
      this.promiseTimer = setInterval(function() {
        let hour = parseInt(self.prizeObj.h);
        let min = parseInt(self.prizeObj.m);
        let sec = parseInt(self.prizeObj.s);
        if (hour === 0) {
          if (min !== 0 && sec === 0) {
            sec = 59;
            min -= 1;
          } else if (min === 0 && sec === 0) {
            sec = 0;
            clearInterval(self.promiseTimer);
            // self.openStatus = true
            self.prizeObj.openStatus = true;
          } else {
            sec -= 1;
          }
        } else {
          if (min !== 0 && sec === 0) {
            sec = 59;
            min -= 1;
          } else if (min === 0 && sec === 0) {
            hour -= 1;
            min = 59;
            sec = 59;
          } else {
            sec -= 1;
          }
        }
        self.prizeObj.h = self.formatNum(hour);
        self.prizeObj.m = self.formatNum(min);
        self.prizeObj.s = self.formatNum(sec);
        return true;
      }, 1000);
    },
    formatNum(num) {
      return num < 10 ? '0' + num : '' + num;
    }
  }
};
